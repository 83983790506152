.App {
  font-family: "Rubik";
}

.App-header {
  height: 100px;
  background-color: #71BF43;
  -webkit-box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.54);
  -moz-box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.54);
  box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.54);
}

.App-content {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
}

.header-logo {
  height: 100px;
  margin-top: 0;
  margin-left: 3px;
}

.date-time {
  float: right;
  margin-right: 20px;
  line-height: 100px;
  color: white;
  font-family: "Rubik";
  font-size: 32px;
}

.dashboard-header-1 {
  margin-bottom: 0.5em;
  font-size: 2.5em;
}

.dashboard-header-2 {
  margin-bottom: 0.5em;
  font-size: 1.5em;
}

.dashboard-tile {
  padding-left: 10px;
  padding-right: 10px;
}