.order-item {
	/*margin-left: 1em;
	height: 12em;
	width: 32em;*/
	padding-top: 1em;
	padding-bottom: 1em;
	font-size: 14px;
}
.order-item:nth-child(odd) {
	background-color: #F0F0F0;
}
.order-item > div {
	float: none;
	display: inline-block;
	vertical-align: middle;
}
.order-datetime {
	height: 9em;
}
.order-datetime div {
	position: absolute;
}
/* Calendar icon widget */
time.calendar-icon {
  font-size: 1em; /* change icon size */
  position: relative;
  left: 50%;
  display: block;
  float: left;
  width: 7.6em;
  height: 8.4em;
  background-color: #fff;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}
time.calendar-icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

/* Day of week */
time.calendar-icon .day-of-week
{
  position: absolute;
  top: 2.7em;
}
/* Hour */
time.calendar-icon .time
{
  position: absolute;
  bottom: 0em;
}

/* Day of month */
time.calendar-icon .day-of-month
{
  position: absolute;
  top: 1.2em;
  font-size: 2.9em;
  letter-spacing: -0.05em;
  color: #2f2f2f;
}

/* Month */
time.calendar-icon .month
{
  position: absolute;
  top: 0;
  padding: 0.4em 0;
  color: #fff;
  background-color: #ff5050;
  border-bottom: 1px dashed #FFFF99;
  box-shadow: 0 2px 0 #ff5050;
}

.order-id {
	font-size: 1.3em;
	margin-top: 0.4em;
	text-align: center;
  font-family: 'Roboto Mono';
}

.partner-logo {
	/*height: 3em;*/
  width: 10em;
	display: block;
	margin: auto;
}

.order-quantity {
	text-align: right;
	padding-right: 40%;
	font-size: 3em;
  margin-bottom: 20px;
}

.order-quantity-detail {
  text-align: right;
  padding-right: 40%;

}

.big-number {
	font-size: 1.5em;
	font-family: 'Roboto Mono';
}
/*.order-quantity-number {
	display: inline-block;
}
.order-quantity-number svg {
	display: inline-block;
	vertical-align: middle;
}
*/
.order-status {
	font-size: 1em;
	text-align: center;
	margin-top: 0.5em;
}
.order-progress {
	font-size: 1.3em;
}

progress {
	-webkit-appearance: none;
	appearance: none;
	height: 2.5em;
	width: 100%;
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  margin-top: calc(-2.5em + 2px);
}

progress::-webkit-progress-value {
   background-image:
	   -webkit-linear-gradient(-45deg, 
	                           transparent 33%, rgba(0, 0, 0, .1) 33%, 
	                           rgba(0,0, 0, .1) 66%, transparent 66%),
	   -webkit-linear-gradient(top, 
	                           rgba(255, 255, 255, .25), 
	                           rgba(0, 0, 0, .25)),
	   -webkit-linear-gradient(left, #71BF43, #71BF43);

    border-radius: 2px; 
    background-size: 5em 2.5em, 100% 100%, 100% 100%;
}


progress:before {
  content: attr(data-label);
  font-size: 1.5em;
  position: relative;
  top: 0;
  left: 0.5em;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px #000000;
}